import React from 'react';
import {Link} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import {Wrapper, Image, ImageWrapper, Description} from './Post.styled';
import Button from '../Button';

const Post = ({post}) => {
  const date = new Date(post.date);

  return (
    <Wrapper>
      <ImageWrapper>
        <Image image={getImage(post.image.value)} alt={post.title.value} />
      </ImageWrapper>
      <Description>
        <h2>
          <Link to={post.title.slug}>{post.title.value}</Link>
        </h2>
        <span>{date.toLocaleDateString('pl-PL', {day: 'numeric', month: 'long', year: 'numeric'})}</span>
        <div dangerouslySetInnerHTML={{__html: post.excerpt.value}}></div>
        <Button as={Link} to={post.title.slug} variant='primary' color='white'>
          Czytaj więcej
        </Button>
      </Description>
    </Wrapper>
  );
};

export default Post;
