import styled from '@emotion/styled';
import {GatsbyImage} from 'gatsby-plugin-image';

export const Image = styled(GatsbyImage)`
`;

export const ImageWrapper = styled.div`
  width: 360px;
  min-width: 360px;
  margin: 0 0 2rem;

  @media (min-width: 1024px) {
    margin: 0 2rem 0 0;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 360px;

  h2 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    line-height: 1.3;
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;

    a {
      color: inherit;
      text-decoration: none;
      transition: color .3s;

      &:hover {
        color: #d72226;
      }
    }
  }

  span {
    margin: 0 0 1rem;
    color: #d72226;
    font-size: .8725rem;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    align-items: flex-start;

    > div {
      flex-grow: 1;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 100%;
  margin: 0 0 3rem;

  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`;
