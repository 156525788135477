import React from 'react';
import {graphql} from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Post from '../components/Post';
import * as style from '../assets/style/pages/blog-page.module.scss';

const BlogPage = ({location, data}) => {
  const posts = data.posts.edges.map(({node}) => node);

  const crumbs = [
    {title: 'Strona główna', url: '/'},
    {title: 'Blog', url: '/blog'}
  ];

  return (
    <Layout>
      <Seo title='Blog'>
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(
                ({title, url}, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `
              )}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.blog}>
        <div className='container'>
          {posts.map((post) => (
            <Post post={post} key={post.id} />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    posts: allCockpitBlog(filter: {published: {value: {eq: true}}}) {
      edges {
        node {
          id
          date: cockpitCreated
          title {
            value
            slug
          }
          image {
            value {
              childImageSharp {
                gatsbyImageData(width: 360, height: 360, quality: 100, placeholder: BLURRED, formats: WEBP)
              }
            }
          }
          excerpt {
            value
          }
          published {
            value
          }
        }
      }
    }
  }
`;

export default BlogPage;
